<template>
  <el-container>
    <el-header>
      <div class="header-content">
        <div class="hader-nav">
          <div class="nav-left" style="display: flex">
            <el-image style="width: 60px;" :src="require('@/assets/images/logo.png')"></el-image>
            <el-text class="mx-1" size="large" style="margin-left: 5px;">轻听音乐</el-text>
          </div>
          <div class="nav-right">
            <div class="info" @click="dialogTableVisible = true">
              <el-icon size="22">
                <Key />
              </el-icon>
            </div>
          </div>
        </div>
      </div>
    </el-header>

    <div class="notice-content">
      <div>
        <el-text line-clamp="1">
          <el-icon>
            <Bell />
          </el-icon>
          感谢您的访问,本站完全免费，从未在任何地方出售，请悉知！点击加入Q群：300939539
        </el-text>
      </div>
    </div>

    <div class="search-content">
      <div class="main-search">
        <el-input v-model="keywords" placeholder="请输入你要搜索的音乐、歌手、专辑" class="input-with-select">
          <template #prepend>
            <el-select v-model="SelectionType" size="large" placeholder="来源" style="width: 100px">
              <el-option v-for="item in SelectOptions" :key="item.type" :label="item.name" :value="item.type" />
            </el-select>
          </template>
          <template #append>
            <el-button type="primary" @click="search">
              <el-icon style="vertical-align: middle">
                <search />
              </el-icon>
            </el-button>
          </template>
        </el-input>
      </div>
      <view id="AudioPlayer"></view>
    </div>

    <el-main>
      <el-scrollbar>
        <el-text>搜索结果</el-text>
        <div class="main-content">
          <div class="search-result">
            <div v-for="(item, key) in list" :key="key" class="text item">
              <div class="search-result-item">
                <div class="search-result-item-avatar">
                  <el-avatar :size="60" :src="item.cover"></el-avatar>
                </div>
                <div class="search-result-item-content">
                  <div class="search-result-item-main-title">
                    <p>
                      <span><el-text>{{ item.song_name }}</el-text></span>
                    </p>
                  </div>
                  <div class="search-result-item-main-subtitle">
                    <p>
                      <el-text type="info">{{ item.song_singer }}</el-text>
                    </p>
                  </div>
                </div>
                <div class="search-result-item-actions">
                  <el-button type="primary" @click="handlePlayMusic(item)" circle>
                    <el-icon>
                      <VideoPlay />
                    </el-icon>
                  </el-button>
                  <el-button type="success" circle @click="handleDownload(item)">
                    <el-icon>
                      <Download />
                    </el-icon>
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </el-main>
    <el-dialog v-model="dialogTableVisible" title="人工验证提示" width="300px">
      <view style="display: flex;flex-direction: column;align-items: center;">
        <el-image style="width: 250px; height: 250px" src="https://a.qytechs.cn/qrcode.jpg" :fit="fit" />
        <view class="unlock_modal_img_desc">
          微信公众号：【X魔盒】
        </view>
        <view class="unlock_modal_text">
          1、微信扫描上方二维码或搜索【X魔盒】
        </view>
        <view class="unlock_modal_text">
          2、公众号中发送【音乐密码】，获取解锁码
        </view>
        <view class="unlock_modal_text">
          填入下方解锁，解锁码有效时间为6小时
        </view>
      </view>
      <div class="mt-4">
        <el-input v-model="password" style="max-width: 600px" placeholder="解锁码">
          <template #append><el-button type="primary" @click="unlock_do">解锁</el-button></template>
        </el-input>
      </div>
    </el-dialog>
    <el-footer>
      <div style="display: flex; justify-content: space-around">
        <el-text line-clamp="1">Copyright © 2024 轻听音乐 保留版权所有 | 技术支持 - 氢云，默然云</el-text>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import { ElMessage } from "element-plus";
import { ElLoading } from 'element-plus';
import "@/assets/css/main.css";
import api from "@/http/api";
import 'aplayer/dist/APlayer.min.css';
import APlayer from 'aplayer';
export default {
  name: "App",
  data() {
    return {
      keywords: "",
      list: [],
      SelectionType: "",
      SelectOptions: [],
      dialogTableVisible: false,
      password: '',
      audio: [{
        name: "",//歌名
        artist: '',//歌手
        url: '',//音频文件地址
        cover: 'h',//音乐封面地址
        lrc: ""// 歌词
      }]
    };
  },
  created() {
    this.getMusicApi();
    this.password = localStorage.getItem('password') || '';
  },
  methods: {
    handlePlayMusic(item) {
      this.audio = [
      {
        name: item.song_name,//歌名
        artist: item.song_singer,//歌手
        url: item.url,//音频文件地址
        cover: item.cover,//音乐封面地址
        lrc: item.lyric// 歌词
      },
    ]
      new APlayer({
        container: document.getElementById("AudioPlayer"),
        audio: this.audio,
        autoplay: true,
      })
    },
    handleDownload(item) {
      //前端实现下载文件
      var a = document.createElement('a');
      a.href = item.url;
      a.download = item.song_name;
      a.click();
    },
    // 获取音乐平台列表
    async getMusicApi() {
      let res = await api.getMusicApi({});
      if (res.code == 1) {
        this.SelectOptions = res.data;
      } else {
        ElMessage.error('系统错误，请联系客服！');
      }
    },
    // 搜索音乐
    async search() {
      if (this.password == '') {
        this.dialogTableVisible = true;
        return false;
      }
      let res = await api.checkUnlockCode({
        password: this.password
      });
      if (res.code != 1) {
        this.dialogTableVisible = true;
        return false;
      }
      var type = this.SelectionType;
      if (this.SelectionType == '') {
        type = this.SelectOptions[0].type
      }
      if (this.keywords == '') {
        ElMessage.error('请输入搜索关键字！');
        return false;
      }
      const loading = ElLoading.service({
        lock: true,
        text: '请求中...',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      let result = await api.search({
        type: type,
        keywords: this.keywords,
        password: this.password
      });
      loading.close();
      if (result.code == 1) {
        if (result.data.length == 0) {
          ElMessage.error('未搜索到相关音乐！');
        } else {
          this.list = result.data;
        }
      } else if (result.code == 401) {
        this.dialogTableVisible = true;
        localStorage.setItem('password', '');
        ElMessage.error('请先解锁！');
      } else {
        ElMessage.error('系统错误，请联系客服！');
      }
    },
    // 解锁
    async unlock_do() {
      if (this.password == '') {
        ElMessage.error('请输入解锁码！');
        return false;
      }
      let res = await api.checkUnlockCode({
        password: this.password
      });
      if (res.code != 1) {
        ElMessage.error('解锁码错误！');
        return false;
      }
      localStorage.setItem('password', this.password);
      this.dialogTableVisible = false;
      ElMessage.success('解锁成功！');
    },
  },
};
</script>

<style>
.flex-grow {
  flex-grow: 1;
}

.unlock_modal_img_desc {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #FF0000;
}

.unlock_modal_text {
  align-self: flex-start;
  margin-top: 5px;
}

.mt-4 {
  margin-top: 20px;
}

.aplayer-body {
  margin: 0 10px;
}
</style>
