import axios from "axios";
import { ElMessage } from "element-plus";

const http = axios.create({
    baseURL: "https://a.qytechs.cn/api/",  //后端接口地址
    timeout: 0, // 请求超时时间
});

//添加请求拦截器
http.interceptors.request.use((config) => {
    //发起请求时，取消掉当前正在进行的相同请求
    if (http.pending && http.pending[config.url]) {
        http.pending[config.url]('操作取消');
        http.pending[config.url] = undefined;
    } else {
        http.pending = {};
    }
    return config;
},
    (error) => {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

//添加响应拦截器
http.interceptors.response.use((response) => {
    // 对响应数据做点什么
    return response.data;
},
    (err) => {
        // 对响应错误做点什么
        if (err && err.response) {
            switch (err.response.status) {
                case 400:
                    err.message = '错误请求'
                    break;
                case 401:
                    err.message = '未授权，请重新登录'
                    break;
                case 403:
                    err.message = '拒绝访问'
                    break;
                case 404:
                    err.message = '请求错误,未找到该资源'
                    break;
                case 405:
                    err.message = '请求方法未允许'
                    break;
                case 408:
                    err.message = '请求超时'
                    break;
                case 500:
                    err.message = '服务器端出错'
                    break;
                case 501:
                    err.message = '网络未实现'
                    break;
                case 502:
                    err.message = '网络错误'
                    break;
                case 503:
                    err.message = '服务不可用'
                    break;
                case 504:
                    err.message = '网络超时'
                    break;
                case 505:
                    err.message = 'http版本不支持该请求'
                    break;
                default:
                    err.message = `连接错误${err.response.status}`
            }
        } else {
            err.message = "连接到服务器失败"
        }
        ElMessage.error(err.message);
        // return Promise.reject(err);
    }
);

export default http;